
export const Navigation = (props) => {
    return (
        <div id='menu' className=''>
            <div className="container">
                <div className="row align-items-center justify-content-between">
                    <div className="logo col-xs-12 col-md-6">
                        <a className='' href='/'>
                            <img style={{ height: 60, overflow: 'hidden' }} className="img-fluid" alt="RO Care Service Center |  ro system repair" title="ro repair service" src="img/rologo.png" />
                        </a>{' '}
                    </div>
                    <div className="contact-info d-flex flex-wrap col-xs-12 col-md-6 justify-content-end align-items-center">
                        {/* Email Section */}
                        <div className="mailnow d-flex align-items-center me-md-4 ">
                            <i className="fa fa-envelope-o me-2"></i>
                            <a href="mailto:roservicesaquafine@gmail.com" className="text-decoration-none">
                                roservicesaquafine@gmail.com
                            </a>
                        </div>

                        {/* Phone Section */}
                        <div className="callnow d-flex align-items-center">
                            <i className="fa fa-phone me-2"></i>
                            <a href="tel:9031062013" className="text-decoration-none">
                                9031062013
                            </a>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}