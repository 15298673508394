import React from "react";

export const Privacypolicy = (props) => {
    return (
        <div id='policy' >

            <div className="solution">
                <div className="container">
                    <a href="/">Back To Home</a>
                    <div className="row">

                        <div className="col-md-7">
                            <div className="filter">
                                <div className="filter-img"><img src="img/wedo/water.jpg" className="img-fluid" alt="ro repair water purifier" /></div>

                            </div>
                        </div>

                        <div className="col-md-5">
                            <h3>Privacy Policy</h3>
                            At Aquafine RO Services, we are committed to protecting your privacy. This policy outlines how we collect, use, and safeguard your personal information. We collect personal details such as your name, contact information, and service history when you interact with us or use our services. This information is used to process your requests, provide customer support, and improve our services.<br /><br />

                            We do not share your personal information with third parties without your consent, except when required by law or to fulfill service-related needs. We employ industry-standard security measures to protect your data from unauthorized access, alteration, or disclosure.<br /><br />

                            By using our services, you consent to the collection and use of your information as described in this policy. If you have any questions or concerns, feel free to contact us.<br /><br />

                            This privacy policy may be updated occasionally, and we encourage you to review it regularly.<br /><br />
                            <a href="/">Back To Home</a>
                        </div>

                    </div>

                </div>

            </div>
            <div className="rorepair">
                <div className="container">
                    <p>Privacy Policy of Aquafine RO Services</p>
                    At Aquafine RO Services, we value the privacy of our customers and are committed to protecting the personal information you provide to us. This Privacy Policy outlines how we collect, use, and protect your data when you interact with our services.
                    <br /><br />

                    1. Information We Collect<br />

                    We collect personal information such as your name, contact details, service requests, and payment information when you interact with our website, request services, or communicate with our customer support team. This data is necessary to process your orders, provide our services, and communicate with you effectively.
                    <br /><br />
                    2. How We Use Your Information<br />

                    The information we collect is used to:<br />

                    Provide and manage the services you request.<br />
                    Process payments and send invoices.<br />
                    Respond to your inquiries and provide customer support.<br />
                    Improve our services and website functionality.<br />
                    Send service updates and promotional offers (only with your consent).
                    <br /><br />
                    3. Data Protection: We take reasonable measures to protect your personal information from unauthorized access, alteration, or disclosure. Our security protocols are regularly reviewed and updated to ensure the highest level of protection.
                    <br /><br />
                    4. Sharing Your Information: We do not share, sell, or rent your personal information to third parties, except when required by law or when it is necessary to fulfill your service requests (such as sharing with service partners or contractors for service execution). Any third-party partners will be required to adhere to strict confidentiality and privacy standards.
                    <br /><br />
                    5. Cookies and Tracking: Our website may use cookies to enhance user experience and analyze website usage. You can adjust your browser settings to refuse cookies or alert you when they are being sent, though this may affect certain website features.
                    <br /><br />
                    6. Data Retention: We retain your personal information only as long as necessary to fulfill the purposes outlined in this policy or as required by law.
                    <br /><br />
                    7. Your Rights: You have the right to access, correct, or delete your personal information. If you wish to update or delete your data, please contact us directly. You also have the right to opt-out of receiving marketing communications at any time.
                    <br /><br />
                    8. Changes to This Policy: Aquafine RO Services may update this Privacy Policy periodically. Any changes will be posted on our website with an updated date. We encourage you to review this policy regularly to stay informed about how we protect your information.
                    <br /><br />

                    9. Contact Us

                    If you have any questions or concerns regarding this Privacy or the handling of your personal data <br />
                    please contact us at:<br />

                    Aquafine RO Services <br />
                    Email: roservicesaquafine@gmail.com<br />
                    Phone: 9031062013<br />
                    Website: aquafineroservices.co.in
                    <br /><br />
                    By using our services, you consent to the terms outlined in this Privacy Policy.
                </div>
            </div>

        </div>
    )
}