export const Chooseus = () => {
    return (
        <div className="ropurifier">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">

                        <div className="ourservice">
                            <h2>Why Choose Us?</h2>
                            <p>
                                <b>Expertise:</b> Our technicians have years of experience and are trained to work with all major brands of RO systems.<br />
                                <b>Quality Service: </b>We prioritize quality and reliability in every service and repair job we undertake.<br />
                                <b>Customer Satisfaction:</b> We strive to exceed customer expectations with our professionalism and commitment to excellence.<br />
                                <b>Affordable Pricing:</b> Transparent pricing with no hidden costs ensures you get value for your money.
                            </p>

                        </div>
                    </div>


                </div>
            </div>
        </div>
    )
}